<template>
  <b-container id="surgery-table" fluid>
    <b-table-simple class="table-eyecare" responsive>
      <thead>
        <tr>
          <th>Horário</th>
          <th>Paciente</th>
          <th>Situação cirurgia</th>
          <th>Profissional</th>
          <th>Sala</th>
          <th>Procedimento</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="surgery in surgeries" :key="surgery.id">
          <td>
            <span>{{ parseDateToTime(surgery?.appointment?.start_datetime) }}</span>
          </td>
          <td>
            <div class="patient-col">
              <PatientInformation :appointment="surgery.appointment" :status="surgery?.appointment?.status"/>

              <a class="ml-2 pointer" @click="goToDashboard(surgery?.appointment?.patient_id)" >
                <Export />
              </a>
              </div>  
          </td>
          <td>
            <Select 
              id="status-select"
              class="status-select"
              :value="surgery?.status"
              :options="status"
              @input="updateSurgeryStatus(surgery, $event)"
              :disabled="updatingStatus || readonly"
              :hideChevron="readonly"
              :backgroundColor="statusLightColors[surgery.status]"
              :fontColor="statusColors[surgery.status]"
              :borderColor="statusColors[surgery.status]"
              borderRadius="25px"
              radius="25px"
              fontWeight="600"
            />
          </td>
          <td>
            <span>{{ surgery?.appointment?.professional?.name }}</span>
          </td>
          <td>
            <span>{{ surgery?.appointment?.room?.name }}</span>
          </td>
          <td>
            <p v-for="procedure in surgery?.procedures" :key="procedure.id">
              {{ procedure?.item?.name }}
              
            </p>
          </td>
          <td>
            <b-button variant="primary" class="dashboard-btn"
              @click="goTo(`/centro-cirurgico/dashboard/${surgery.id}`)"
            >
              <ChevronRight class="icon"/>
            </b-button>
          </td>
        </tr>
      </tbody>
    </b-table-simple>
  </b-container>
</template>

<script>
import { parseDate, parseDateToTime, getAge } from '@/utils/dateHelper'
import { getSurgeryStatus } from '@/utils/surgeryCenterHelper'

export default {
  name: 'PatientList',

  components: {
    Export: () => import('@/assets/icons/export.svg'),
    Select: () => import('@/components/Select.vue'),
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
    PatientInformation: () => import('@/components/Overview/ScheduleTablePatientInformation'),
  },

  props: {
    surgeries: Array,
    readonly: Boolean
  },

  data () {
    return {
      updatingStatus: false,
      statusColors: {
        'Reservado': 'var(--blue-500)',
        'Confirmado': 'rgba(0, 151, 124, 1)',
        'Primeiro atendimento': 'rgba(0, 194, 255, 1)',
        'Admissão finalizada': 'rgba(4, 227, 107, 1)',
        'Pronto para cirurgia': 'rgba(197, 86, 215, 1)',
        'Em cirurgia': 'rgba(255, 171, 40, 1)',
        'Sala de recuperação': 'rgba(194, 114, 69, 1)',
        'Finalizado': 'var(--neutral-700)',
        'Faltou': 'rgba(255, 105, 105, 1)',
        'Cancelado': 'rgba(127, 145, 210, 1)',
      },
      statusLightColors: {
        'Reservado': 'rgba(72, 110, 246, 0.2)',
        'Confirmado': 'rgba(0, 151, 124, 0.2)',
        'Primeiro atendimento': 'rgba(0, 194, 255, 0.2)',
        'Admissão finalizada': 'rgba(219, 255, 240, 0.4)',
        'Pronto para cirurgia': 'rgba(250, 222, 255, 0.4)',
        'Em cirurgia': 'rgba(255, 171, 40, 0.2)',
        'Sala de recuperação': 'rgba(194, 114, 69, 0.2)',
        'Finalizado': 'var(--neutral-100)',
        'Faltou': 'rgba(255, 105, 105, 0.3)',
        'Cancelado': 'rgba(127, 145, 210, 0.2)',
      },
    }
  },

  computed: {
    status: function () { 
      const stts = getSurgeryStatus() 
      return stts.map((status) => ({ value: status, label: status }))
    },
  },

  methods: {
    getAge,
    parseDate,
    parseDateToTime,
    getTime(datetime) {
      return this.moment(datetime).format('HH:mm')
    },
    goToDashboard (patientId) {
      const route = this.$router.resolve(`/pacientes/${patientId}`)
      window.open(route.href, '_blank');
    },
    async updateSurgeryStatus (surgeryInformation, status) {
      try {
        this.updatingStatus = true
        surgeryInformation.status = status 
        await this.api.updateSurgeryInformation(surgeryInformation.id, { status: status })
      } catch (error) {
        this.$toast.error('Não foi possível atualizar o status da cirurgia, contate o suporte se o erro persistir.')
      } finally {
        this.updatingStatus = false
      }
    },
    goTo(path) {
      this.$router.push(path)
    },
  }
}
</script>

<style lang="scss">
#surgery-table {
  padding: 0;
  table {
    td { 
      padding: 16px 0px;
      vertical-align: middle;
      .patient-col {
        display: flex;
        align-items: center;
      }

      .status-select {
        width: 70%;
      }

      .pointer { cursor: pointer; }

      span {
        max-width: 25ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .dashboard-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40px;
      
        .icon {
          stroke: var(--neutral-000);
          width: 22px;
          height: 22px;
          display: block;
        }
      }
    }
  }
}
</style>